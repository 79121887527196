<template>
  <WeContainer header="true">
    <template>
      <label>Yüklemek İstediğiniz Veri Tipini Seçiniz</label>
      <v-select
        label="name"
        class="custom-v-select"
        v-bind:clearable="true"
        v-model="dataType"
        v-bind:options="dataTypes"
      ></v-select>
      <div class="mb-3 mt-3" v-if="dataType && dataType.key == 'blog'">
        <div class="row">
          <div class="col-md-6 col-lg-6">
            <WeInput
              class="mb-0 text-right"
              v-model="blogNames.tr"
              v-bind:placeholder="'Türkçe Blog Adını Girin'"
            />
          </div>
          <div class="col-md-6 col-lg-6">
            <WeInput
              class="mb-0 text-right"
              v-model="blogNames.en"
              v-bind:placeholder="'İngilizce Blog Adını Girin'"
            />
          </div>
        </div>
      </div>
      <div class="mb-3 mt-3" v-if="dataType.key != 'blog'">
        <label class="custom-label">Dil Seçin</label>
        <div class="d-block mb-1">
          <small class="text-muted"
            >Hazırladığınız excel verisinin hangi dile oluşturulmasını
            istediğinizi seçiniz.</small
          >
        </div>
        <WeLanguageSwitcher v-model="language" />
      </div>
    </template>
    <div class="row" v-if="dataType && dataType.key == 'blog'">
      <div class="col-6">
        <WeFileUploader
          title="Türkçe Masaüstü Dosyası"
          v-model="blogFiles.tr_desktop"
          meta-visible=""
          :acceptedMimeTypes="'.xlsx,.xls'"
          sizeLimit="20480"
        />
      </div>
      <div class="col-6">
        <WeFileUploader
          title="Türkçe Mobil Dosyası"
          v-model="blogFiles.tr_mobile"
          meta-visible=""
          :acceptedMimeTypes="'.xlsx,.xls'"
          sizeLimit="20480"
        />
      </div>
      <div class="col-6">
        <WeFileUploader
          title="İngilizce Masaüstü Dosyası"
          v-model="blogFiles.en_desktop"
          meta-visible=""
          :acceptedMimeTypes="'.xlsx,.xls'"
          sizeLimit="20480"
        />
      </div>
      <div class="col-6">
        <WeFileUploader
          title="İngilizce Mobil Dosyası"
          v-model="blogFiles.en_mobile"
          meta-visible=""
          :acceptedMimeTypes="'.xlsx,.xls'"
          sizeLimit="20480"
        />
      </div>
    </div>
    <WeFileUploader
      v-else
      v-model="file"
      meta-visible=""
      :acceptedMimeTypes="'.xlsx,.xls'"
      sizeLimit="20480"
    />
    <div class="text-center">
      <WeSubmitButton
        v-bind:submit-status="submit"
        v-on:submit="onSave"
        :btnText="'Verileri Yükle'"
      />
    </div>
    <hr />
    <div
      class="row"
      v-if="
        dataType &&
        (dataType.key == 'blog' || dataType.key == 'rolex_product_detail')
      "
    >
      <div class="col-12">
        <WeImageUploader
          v-model="media"
          :title="`${dataType.key == 'blog' ? 'Blog' : 'Ürün'} Görselleri`"
          meta-visible=""
          multiple="1"
          :acceptedMimeTypes="'.png,.jpg,.jpeg,.webp'"
          sizeLimit="20480"
        />
      </div>
      <div class="col-12 text-center mt-4">
        <WeSubmitButton
          v-bind:submit-status="saveImageSubmit"
          v-on:submit="onSaveImage"
          :btnText="'Görselleri Yükle'"
        />
      </div>
    </div>
  </WeContainer>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "ImportDataHome",
  data() {
    return {
      submit: false,
      language: "tr",
      saveImageSubmit: false,
      media: {
        files: [],
        filesData: null,
      },
      blogFiles: {
        tr_desktop: {
          files: [],
          filesData: null,
        },
        tr_mobile: {
          files: [],
          filesData: null,
        },
        en_desktop: {
          files: [],
          filesData: null,
        },
        en_mobile: {
          files: [],
          filesData: null,
        },
      },
      blogNames: {
        tr: null,
        en: null,
      },
      dataType: { key: null },
      file: {
        files: [],
        filesData: null,
      },
      dataTypes: [
        { name: "Blog Yükle", key: "blog" },
        { name: "Ürün Özellikleri (Icon)", key: "spec_desc" },
        {
          name: "Ürün Özellikleri (Tablo)",
          key: "table_desc",
        },
        {
          name: "Rolex Ürün Detay",
          key: "rolex_product_detail",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      importDataUgurSaat: "listimport/importDataUgurSaat",
      importDataSaveImage: "listimport/importDataSaveImage",
    }),
    onSave() {
      if (!this.dataType) {
        this.$toast.error("Lütfen bir veri tipi seçin!");
        return;
      }

      let files = {};
      if (this.dataType.key == "blog") {
        if (!this.blogNames.tr || !this.blogNames.en) {
          this.$toast.error("Lütfen tüm blog isimlerini doldurun");
          return;
        }
        if (
          !this.blogFiles.tr_desktop.files.length ||
          !this.blogFiles.tr_mobile.files.length ||
          !this.blogFiles.en_desktop.files.length ||
          !this.blogFiles.en_mobile.files.length
        ) {
          console.log(this.blogFiles);
          this.$toast.error("Lütfen tüm dosya alanlarını doldurunuz.");
          return;
        }
      } else {
        if (!this.file.files.length) {
          this.$toast.error("Lütfen bir dosya seçin!");
          return;
        }
      }
      if (this.dataType.key == "blog") {
        files = {
          tr_desktop: this.blogFiles.tr_desktop.files[0].file,
          tr_mobile: this.blogFiles.tr_mobile.files[0].file,
          en_desktop: this.blogFiles.en_desktop.files[0].file,
          en_mobile: this.blogFiles.en_mobile.files[0].file,
        };
      }

      this.submit = true;
      this.importDataUgurSaat({
        fields: {
          language: this.language,
          type: this.dataType.key,
          blogNames: this.blogNames,
        },
        ...files,
        file: this.dataType.key != "blog" ? this.file.files[0].file : null,
        onSuccess: (result) => {
          if (result?.data?.error) {
            this.$toast.error(result?.data?.error);
          } else if (result?.data?.status) {
            this.$toast.success("İmport işlemi tamamlandı!");
          }
        },
        onError: () => {},
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    onSaveImage() {
      if (!this.media.files.length) {
        this.$toast.error("Lütfen en az 1 adet resim seçin");
        return;
      }
      this.saveImageSubmit = true;
      this.importDataSaveImage({
        media: this.media,
        dataType: this.dataType.key,
        onSuccess: (result) => {
          if (result?.data?.error) {
            this.$toast.error(result?.data?.error);
          } else if (result?.data?.status) {
            this.$toast.success("Görsel yükleme işlemi tamamlandı");
          }
        },
        onError: () => {},
        onFinish: () => {
          this.saveImageSubmit = false;
        },
      });
    },
  },
};
</script>

<style>
</style>